
import React from "react"
import styled from "styled-components"


const Image = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
`;

export default class MadeBy extends React.Component {


  render() {
    return (
      <a href="https://6f.digital" target="_blank" rel="noreferrer">
        <Image src="https://assets.6f.digital/made-by-6f/made-by-6f-black.svg" alt="Made with ♥ by 6F" />
      </a>
    )
  }
}